.img-dropzone {
  text-align: center;
  max-width: 100%;
  margin-bottom: 20px;
  position: relative;
  width: 300px;
  height: 200px;
  border: 2px dashed #666;
  border-radius: 5px;
  padding: 1px;
}

.input-2 {
  border: 0;
  outline: 0;
  box-shadow: none !important;
}

.input-2:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.paymethod-box {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.paymethod-box input[type=radio] {
  position: absolute;
  left: -9999999px;
}

.paymethod-box input:checked+.label {
  background-color: rgba(79, 70, 229);
  color: #fff;
}

.paymethod-box label .label {
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  border-left: 0;
  padding: 12px;
  text-align: center;
  width: 160px;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s ease;
}

.paymethod-box label:first-child .label {
  border-left: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.paymethod-box label:last-child .label {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}